import { useState, useContext } from "react";
import { link } from "./link";
import axios from "axios";
import { EvolvedSearchCard, SearchCard } from "../components/Cards";
import { LangContext } from "../components/Language";

export function SearchPage(){
    const lang = useContext(LangContext);
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const onSearch = () => {
        setLoading(true);
        axios.post(link.baseURL + '/app/customer/basket', { searchkey: document.getElementById('searchkey').value }, {
            headers : {
                'Content-Type' : 'application/json'
            }
        }).then((res) => {
            console.log(res.data)
            setResult(res.data.reverse());
            setLoading(false);
        }).catch(console.error);
    }
    if(loading) return(<div>Đang tải...</div>)
    return(
        <>
        <div className="m-3 border p-3">
            <label htmlFor="searchkey" className="form-label">{lang.searchpage_label}</label>
            <input type="text" className="form-control mb-1" id="searchkey" placeholder={lang.searchpage_placeholder}/>
            <button className="btn btn-success" onClick={onSearch}>{lang.searchpage_send}</button>
        </div>
        
        
        <div className="container">
            <h4>{lang.searchpage_title}</h4>
        {
            result && result.length != 0 ? result.map((value, index) => {
                return(<EvolvedSearchCard value={value} id={'d' + index}/>);
            }) : (<div>{lang.searchpage_status}</div>)
        }
        </div>
        </>
    );
}