import { useContext, useEffect, useMemo, useState } from 'react';
import './components.css'
import { LangContext } from './Language';
import logo from './logo.jpg'

export const formatter = new Intl.NumberFormat('vi-VI', {
    style: 'currency',
    currency: 'VND',
  });

export function TicketCard({value, image, onAddClick, onExcludeClick, onPlus, onMinus}){
    //const value = data.data();
    return (
        <div className={"TicketCard" + (value.num != 0 ? " Picked" : "")}>
            {/* Image */}
        <div className='ImageContainer'>
            <img src={'./ticket_4271.png'} alt='not found'/>
        </div>
        <h6>{value.name}</h6>
        <h6>Giá: {value.price}</h6>
        {
            value.num == 0 ? (<button className="adding" onClick={onAddClick}>Thêm vào giỏ hàng</button>) : (
                <>
                <button className='changingNum' onClick={onPlus}>+</button>
                <div>Số lượng: {value.num}</div>
                <button className='changingNum' onClick={onMinus}>-</button>
                <button className='ExcludeButton' onClick={onExcludeClick}>Bỏ khỏi giỏ hàng</button>
                </>
            )
        }
        </div>
    )
}

export function TicketCardEvolved({value, mode, onPlus0, onPlus1, onMinus0, onMinus1}){
    const lang = useContext(LangContext);
    const current1 = useMemo(() => {
        if(value.apply == 1 && mode == 1) return(<>
            <p className='text-decoration-line-through'>Người lớn: {formatter.format(value.price[0])} </p>
            <p>Người lớn: {formatter.format(value.price[2])} </p>
            </>);
        else if(value.apply == 1 && mode == 0) return(<p>Người lớn: {formatter.format(value.price[0])} </p>);
        else if(value.apply == 0) return(<p>Người lớn: {formatter.format(value.price[0])} </p>);
        else if(value.apply == 2) return(<>
            <p className='text-decoration-line-through'>Người lớn: {formatter.format(value.price[0])} </p>
            <p>Người lớn: {formatter.format(value.price[4])} </p>
            </>);
    }, [mode]);
    const current2 = useMemo(() => {
        if(value.apply == 1 && mode == 1) return(<>
            <p className='text-decoration-line-through'>Trẻ em dưới 1m4: {formatter.format(value.price[1])} </p>
            <p>Trẻ em dưới 1m4: {formatter.format(value.price[3])} </p>
            </>);
        else if(value.apply == 1 && mode == 0) return(<p>Trẻ em dưới 1m4: {formatter.format(value.price[1])} </p>);
        else if(value.apply == 0) return(<p>Trẻ em dưới 1m4: {formatter.format(value.price[1])} </p>);
        else if(value.apply == 2) return(<>
            <p className='text-decoration-line-through'>Trẻ em dưới 1m4: {formatter.format(value.price[1])} </p>
            <p>Trẻ em dưới 1m4: {formatter.format(value.price[5])} </p>
            </>);
    }, [mode]);
    return (
        <div className={"card " + (value.num[0] == 0 && value.num[1] == 0 && value.num[2] == 0 ? "" : "border border-success")} style={{width: 40 + 'rem'}}>
            <div className="card-body">
                <h5 className="card-title">{value.name[`${lang.flag}`]}</h5>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + value.ticket_id} aria-expanded="true" aria-controls={"#collapseOne" + value.ticket_id}>
                            Chọn vé
                        </button>
                        </h2>
                        <div id={"collapseOne" + value.ticket_id} className="accordion-collapse collapse show">
                            <div className="accordion-body">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        {current1}
                                        <div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn btn-light" onClick={onPlus0}>+</button>
                                            <button type="button" className="btn btn-light">{value.num[0]}</button>
                                            <button type="button" className="btn btn-light" onClick={onMinus0}>-</button>
                                        </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        {current2}
                                        <div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn btn-light" onClick={onPlus1}>+</button>
                                            <button type="button" className="btn btn-light">{value.num[1]}</button>
                                            <button type="button" className="btn btn-light" onClick={onMinus1}>-</button>
                                        </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        Trẻ em dưới 80cm: Miễn phí
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwo" + value.ticket_id} aria-expanded="false" aria-controls={"#collapseTwo" + value.ticket_id}>
                            Chi tiết
                        </button>
                        </h2>
                        <div id={"collapseTwo" + value.ticket_id} className="accordion-collapse collapse">
                            <div className="accordion-body">
                                <p className="card-text" dangerouslySetInnerHTML={{__html: value.des[`${lang.flag}`]}}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function BasketCard({value, onExcludeClick}){
    return (
        <div className='BasketCard'>
            <h6>{value.name}</h6>
            <h6>Giá: {value.price}</h6>
            <h6 style={{color: 'black'}}>Số lượng: {value.num}</h6>
            <h6>Ngày đi trên vé: {value.date_on_ticket}</h6>
            <button className='ExcludeButton' onClick={onExcludeClick}>Bỏ khỏi giỏ hàng</button>
        </div>
    )
}

export function EvolvedSearchCard({value, id}){
    const lang = useContext(LangContext);
    return(
        <div className='card card-body' id={id}>
            <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th rowSpan={2}>
                                <img className='mx-auto' src={logo} alt='Not found' width={100}/>
                            </th>
                            <th>Nam Đà Thành</th>
                        </tr>
                        <tr>
                            Hot Spring Park Confirmation
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{lang.ticket_code}</td>
                            <td>{value.booking_code}</td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_date_on_ticket}</td>
                            <td>{value.date_book}</td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_full_name}</td>
                            <td>{value.name}</td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_phone}</td>
                            <td>{value.phone}</td>
                        </tr>
                        <tr>
                            <td>{lang.ticket_idcard}</td>
                            <td>{value.uid}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <table className='table table-striped-columns'>
                <thead>
                    <tr>
                        <td>{lang.ticket}</td>
                        <td colSpan={2}>{lang.ticket_quantity}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        value.ticket.map(element => {
                            if(element.num[0] != 0 || element.num[1] != 0) return(
                                <>
                                <tr>
                                    <td rowSpan={2}>
                                        <h6>{element.name[`${lang.flag}`]}</h6>
                                    </td>
                                    <td>{lang.ticket_adult}</td>
                                    <td>{element.num[0]}</td>
                                    
                                </tr>
                                <tr>
                                        <td>{lang.ticket_children}</td>
                                        <td>{element.num[1]}</td>
                                        </tr>
                                </>
                            )
                        })
                    }
                    <tr>
                        <td>{lang.ticket_total}</td>
                        <td colSpan={2}>{formatter.format(value.money)}</td>
                    </tr>
                </tbody>
            </table>
            <table className='table'>
                <thead>
                    <tr>
                        <th>{lang.ticket_note}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{lang.ticket_note1}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note2}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note3}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note4}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note5}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note6}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note7}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note8}</td>
                    </tr>
                    <tr>
                        <td>{lang.ticket_note9}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export function SearchCard({value}){
    return (
        <div className='card card-body'>
            <h6>Họ và tên: {value.name}</h6>
            <h6>Số điện thoại: {value.phone}</h6>
            <h6>CMND: {value.uid}</h6>
            <table className='table table-striped-columns'>
                <thead>
                    <tr>
                        <th>Vé</th>
                        <th>Số lượng</th>
                    </tr>
                </thead>
                <tbody>
                {
                    value.ticket.map(element => {
                        return(
                            <tr>
                                <td>
                                    <h6>{element.name}</h6>
                                </td>
                                <td>
                                    <h6>
                                        Người lớn - {element.num[0]}
                                    </h6>
                                    <h6>
                                        Trẻ em - {element.num[1]}
                                    </h6>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            
            <h6>Ngày đặt vé: {value.date_book}</h6>
            <h6>Giá: {value.money}</h6>
            <h6 style={{color: 'black'}}>Mã booking: {value.booking_code}</h6>
            <h6 style={{color: 'black'}}>Ngày đi trên vé: {value.date_on_ticket}</h6>
        </div>
    )
}

export function HistoryCard({value}){
    let d = value.date_on_ticket.toDate();
    return (
        <button  className='HistoryCard'>
            {
                value.ticket.map(element => {
                    return(
                        <>
                        <h6>{element.name} - Số lượng: {element.num}</h6>
                        </>
                    )
                })
            }
            <h6>Ngày đặt vé: {Date(value.date_book)}</h6>
            <h6>Giá: {value.money}</h6>
            <h6 style={{color: 'black'}}>Mã booking: {value.booking_code}</h6>
            <h6 style={{color: 'black'}}>Ngày đi trên vé: {"" + d.getDate() + " - " + d.getMonth() + " - " + d.getFullYear()}</h6>
        </button>
    )
}