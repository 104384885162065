import { HugeButton, IntroButton } from "../components/Buttons"
import { Link } from "react-router-dom"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import image1 from '../components/1.jpg';
import image2 from '../components/2.jpg';
import image3 from '../components/3.jpg';
import image4 from '../components/4.jpg';
import image5 from '../components/5.jpg';
import image6 from '../components/6.jpg';

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '600px'
  }
  const slideImages = [
    {
      url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
    },
    {
      url: image1
    },
    {
      url: image2
    },
    {
      url: image3
    },
    {
      url: image4
    },
    {
      url: image5
    },
    {
      url: image6
    }
  ];
  
  const Slideshow = () => {
      return (
        <div className="slide-container">
          <Slide>
           {slideImages.map((slideImage, index)=> (
              <div key={index}>
                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                  {/* <span style={spanStyle}>{slideImage.caption}</span> */}
                </div>
              </div>
            ))} 
          </Slide>
        </div>
      )
  }

export function Home() {
    let toggledColor = true;
    const data = [
        'Hướng dẫn mua vé',
        'Trải nghiệm tuyệt vời tại những khu vui chơi khác biệt',
        'Trải nghiệm khác lạ blah blah'
    ]
    const intro = [0, 1, 2];
    return (
        <>
        <Slideshow/>
            <div className="banner">
                <div>
                    <h1>Trải nghiệm du lịch khác biệt</h1>
                </div>
                <div>
                    <Link to="/ticket"><HugeButton /></Link>
                </div>
            </div>
            {data.map((item, index) => {
                toggledColor = !toggledColor;
                return(
                    <div key={index} className="bannerIntro" style={toggledColor ? {backgroundColor: '#DDFFBC'} : {backgroundColor: '#FEFFDE'}}>
                        <h3>{item}</h3>
                        <Link to={'/intro/' + intro[index]}><IntroButton /></Link>
                    </div>
                )
            })}
            <div className="footer">
                <h2>Thông tin liên lạc hỗ trợ khi gặp sự cố hoặc trao đổi trực tiếp</h2>
                <h4>Zalo: 0338289786</h4>
                <h4>Gmail: thichngao@gmail.com</h4>
            </div>
        </>
    )
}